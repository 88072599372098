import { MetaHead } from 'components/MetaHead/MetaHead';
import { Header } from 'components/Header/Header';
import { Footer } from 'components/Footer/Footer';
import { TemplateError } from 'components/TemplateError/TemplateError';

export default function Custom404() {
  return (
    <>
      <MetaHead
        url="/404"
        title="Seite nicht vorhanden - Finanzcheck.de"
        index={false}
        follow={false}
      />
      <Header headerStyle="full" />
      <TemplateError statusCode={404} />
      <Footer footerStyle="full" />
    </>
  );
}
